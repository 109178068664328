import "./style.scss";

// import { phrases } from "/data/test";

import { randInt, sample, sampleProp, shuffle } from "./common/common";
// import images from "./img/*.png";
// import svgs from "./img/icons/*.svg";
// import pngs from "./img/icons/*.png";

// import gsap from "gsap";

import { Band } from './band';
// import { Bios } from './bios'


import "@fortawesome/fontawesome-free/css/all.min.css";

if (document.readyState !== "loading") init();
else document.addEventListener("DOMContentLoaded", init);


// let wh, date, cf, popp, popy, drawCloud;

let band;
// let bios;

function init() {
  
  band = new Band();
  // bios = new Bios();
    //testSvg();

    // wh = document.querySelector('#wholesome');
    // date = document.querySelector('#date');
    // cf = document.querySelector('#cloud_f');
    // popp = document.querySelector('#pop_pink');
    // popy = document.querySelector('#pop_yellow');
    // drawCloud = document.querySelector('.cloud-path-solid');

    // setTimeout(testSvg, 200);
    // addHover();

}

function addHover() {
    const form = document.querySelector('#mc_embed_signup');
    const lily = document.querySelector('.lily');
    form.addEventListener("mouseover", () => {   
      lily.classList.add("wiggle");  
    }, false);

    form.addEventListener("mouseleave", () => {   
        lily.classList.remove("wiggle");  
      }, false);
    
}