export class Band {
  constructor() {
    var AudioContext = window.AudioContext || window.webkitAudioContext;
    this.ctx = new AudioContext();
    this.gainNode = this.ctx.createGain();

    this.gainNode.connect(this.ctx.destination);

    this.loaded = false;
    this.playing = false;

    
    this.files = {
      bass_1: new URL("./assets/audio/Bass 1.mp3", import.meta.url),
      bass_2: new URL("./assets/audio/Bass 2.mp3", import.meta.url),
      drums_1: new URL("./assets/audio/Drums 1.mp3", import.meta.url),
      instrument_1: new URL("./assets/audio/Instrument 1.mp3", import.meta.url),
      instrument_2: new URL("./assets/audio/Instrument 2.mp3", import.meta.url),
      instrument_3: new URL("./assets/audio/Instrument 3.mp3", import.meta.url),
      vox_1: new URL("./assets/audio/Vox 1.mp3", import.meta.url),
      vox_2: new URL("./assets/audio/Vox 2.mp3", import.meta.url),
    };

    // this.files = {
    //   bass_1: bass_1,
    //   bass_2: bass_2,
    //   drums_1: drums_1,
    //   instrument_1: instrument_1,
    //   instrument_2: instrument_2,
    //   instrument_3: instrument_3,
    //   vox_1: vox_1,
    //   vox_2: vox_2,
    // };

    // this.files = {
    //   bass_1: tracks["Bass 1"],
    //   bass_2: tracks["Bass 2"],
    //   drums_1: tracks["Drums 1"],
    //   instrument_1: tracks["Instrument 1"],
    //   instrument_2: tracks["Instrument 2"],
    //   instrument_3: tracks["Instrument 3"],
    //   vox_1: tracks["Vox 1"],
    //   vox_2: tracks["Vox 2"],
    // };

    this.audio = {};
    this.loadCount = Object.keys(this.files).length;

    // this.startTime = null;

    this.offset = 0;

    this.vox3 = document.querySelector("#vox_3");

    this.playBtn = document.querySelector("#play_btn");

    // this.clock

    for (const [key, file] of Object.entries(this.files)) {
      //   let source = this.ctx.createBufferSource();
      let request = new XMLHttpRequest();

      request.open("GET", file, true);
      request.responseType = "arraybuffer";

      request.onload = (e) => {
        this.audio[key] = {
          node: document.querySelector(`#${key}`),
          data: request.response,
          decoding: false,
          playing: false,
          source: null,
          buffer: null,
        };
        console.log(`${key} loaded`);
        this.loadCount--;
        if (this.loadCount === 0) {
          this.allLoaded();
        }
        // console.log("loaded");
        // console.log(key);
        // let audioData = request.response;
        // this.ctx.decodeAudioData(
        //   audioData,
        //   function (buffer) {
        //     source.buffer = buffer;
        //     this.loadCount--;
        //     if (this.loadCount === 0) {
        //       this.buffered = true;
        //       console.log("all buffered");
        //       //   playall();
        //     }
        //   },

        //   function (e) {
        //     console.log(`Error with decoding audio data: ${e.err}`);
        //   }
        // );
      };
      request.send();
    }
  }

  allLoaded() {
    if (this.loaded) return;

    console.log("all loaded");

    Object.keys(this.audio).forEach((key) => {
      let node = this.audio[key].node;
      console.log(node.id);
      node.addEventListener(
        "click",
        () => {
          this.togglePlay(key);
        },
        false
      );
    });

    this.vox3.addEventListener(
      "click",
      () => {
        this.togglePlay("vox_1");
      },
      false
    );

    this.playBtn.addEventListener(
      "click",
      () => {
        if (this.playCount() == 0) this.togglePlay("bass_1");
        else this.stopAll();
      },
      false
    );

    // document.querySelector('.track').addEventListener('click', () => {
    //     if(!this.restructure) {
    //         this.restructure = new RestructurePalette(this.restructureDiv);

    //     } else {

    //     }
    //     this.restructure.make(this.ramps, this.imgNode.src)
    //     // this.restructure = new RestructurePalette();

    // }, false);

    this.loaded = true;
  }

  togglePlay(key) {
    // const n = this.playCount();

    if (this.audio[key].decoding) return;

    if (!this.audio[key].playing) {
      if (this.ctx.state !== "running") {
        this.ctx.resume();
      }
      if (this.audio[key].buffer != null) {
        this.playBuffer(key);
      } else {
        this.audio[key].decoding = true;
        this.ctx.decodeAudioData(
          this.audio[key].data,
          (buffer) => {
            this.audio[key].buffer = buffer;
            this.playBuffer(key);
          },

          function (e) {
            console.log(`Error with decoding audio data: ${e.err}`);
          }
        );
      }
    } else {
      this.audio[key].decoding = false;
      this.audio[key].playing = false;
      this.audio[key].node.classList.remove("playing");
      if (key == "vox_1") {
        this.vox3.classList.remove("playing");
      }
      let source = this.audio[key].source;
      try {
        source.stop();
      } catch (e) {
        console.log("Caught invalid stop");
      }

      if (this.playCount() == 0) {
        this.playBtn.classList.remove("playing");
      }
    }

    // const playing = (this.audio[key].playing = !this.audio[key].playing);
  }

  playBuffer(key) {
    if (this.audio[key].playing) return;

    if (this.playCount() == 0) {
      this.playBtn.classList.add("playing");
    }

    this.audio[key].playing = true;
    this.audio[key].decoding = false;
    this.audio[key].node.classList.add("playing");

    if (key == "vox_1") {
      this.vox3.classList.add("playing");
    }

    let source = this.ctx.createBufferSource();
    this.audio[key].source = source;
    source.buffer = this.audio[key].buffer;
    // source.connect(this.ctx.destination);
    source.connect(this.gainNode);
    source.loop = true;
    if (this.offset == 0) {
      this.offset = this.ctx.currentTime;
      console.log("[reset offset]");


      // const mediaDest = this.ctx.createMediaStreamDestination();
      // this.gainNode.connect(mediaDest);


      // let audioChunks = []
      // // const mediaRecorder = new MediaRecorder( mediaDest.stream, { mimeType: 'audio/webm' } );
      // const mediaRecorder = new MediaRecorder( mediaDest.stream );

      // mediaRecorder.onstart = (event) =>
      //   { console.log('Started recording output...') };
      // mediaRecorder.ondataavailable = (event) =>
      //   { audioChunks.push( event.data ) };
      // // mediaRecorder.onstop = (event) =>
      // //   { console.log('Completed Recording', new Blob( audioChunks ) ) };

      // mediaRecorder.start();

      // // setTimeout( ()=>{ mediaRecorder.stop(); }, 3000);


      // this.playBtn.addEventListener(
      //   "mouseover",
      //   () => {
      //     mediaRecorder.stop();
      //   },
      //   false
      // );


      // mediaRecorder.onstop = function(e) {
      //   console.log("data available after MediaRecorder.stop() called.");
  
      //   var clipName = 'recording'
  
      //   var clipContainer = document.createElement('article');
      //   var clipLabel = document.createElement('p');
      //   var audio = document.createElement('audio');
      //   var deleteButton = document.createElement('button');
       
      //   clipContainer.classList.add('clip');
      //   audio.setAttribute('controls', '');
      //   deleteButton.innerHTML = "Delete";
      //   clipLabel.innerHTML = clipName;
  
      //   clipContainer.appendChild(audio);
      //   clipContainer.appendChild(clipLabel);
      //   clipContainer.appendChild(deleteButton);

      //   document.querySelector('.band-block').appendChild(clipContainer);
  
      //   audio.controls = true;
      //   // var blob = new Blob(audioChunks);
      //   var blob = new Blob(audioChunks, { 'type' : 'audio/ogg; codecs=opus' });
      //   audioChunks = [];
      //   var audioURL = URL.createObjectURL(blob);
      //   audio.src = audioURL;
      //   console.log("recorder stopped");
  
      // }



      try {
        source.start(0);
      } catch (e) {
        console.log("Caught invalid start");
      }

    } else {
      try {
        // source.start(0, this.ctx.currentTime - this.offset);
        source.start(0, (this.ctx.currentTime - this.offset) % source.buffer.duration);

        
      } catch (e) {
        console.log("Caught invalid stop");
      }
    }
  }

  playCount() {
    let n = 0;
    Object.values(this.audio).forEach((track) => {
      if (track.playing) n++;
    });
    return n;
  }

  stopAll() {
    Object.keys(this.audio).forEach((key) => {
      if (this.audio[key].playing) {
        this.audio[key].decoding = false;
        this.audio[key].playing = false;
        this.audio[key].node.classList.remove("playing");
        if (key == "vox_1") {
          this.vox3.classList.remove("playing");
        }
        let source = this.audio[key].source;

        try {
          source.stop();
        } catch (e) {
          console.log("Caught invalid stop");
        }
      }
    });
    this.playBtn.classList.remove("playing");
    this.offset = 0;
  }
}
